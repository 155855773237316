exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._4e8X9 .page__body{background:var(--neutral--100);background:var(--neutral--100)}.hh5DT{padding-left:20px;padding-right:20px;margin:var(--block--spacing--xxl) 0;margin:var(--block--spacing--xxl) 0}._1j57R{margin:0 auto;max-width:450px;width:50vw;min-width:300px;background:#fff;-webkit-box-shadow:var(--base--box-shadow);box-shadow:var(--base--box-shadow);box-shadow:var(--base--box-shadow);border-radius:var(--base--border-radius);border-radius:var(--base--border-radius);-webkit-transition:var(--base--transition);transition:var(--base--transition);transition:var(--base--transition);overflow:hidden}._1j57R h1{padding:15px;background:var(--primary--400);background:var(--primary--400);color:#fff;margin:0;font-size:22.4px;font-size:1.4rem}._1j57R ._2bUts{padding:30px}.tA1Zd{background:var(--neutral--100);background:var(--neutral--100);padding:15px;text-align:center}.tA1Zd p{margin:0}.tA1Zd ._1imjM{text-align:center}._2x8AR{font-size:16px;text-align:center;margin:15px 0}._2Jhpy{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}._2Jhpy ._1imjM{text-align:center;margin:0}.wLnpJ{overflow:hidden}", ""]);

// exports
exports.locals = {
	"page": "_4e8X9",
	"root": "hh5DT",
	"container": "_1j57R",
	"registerContainer": "_2bUts",
	"footerContent": "tA1Zd",
	"switchMessage": "_1imjM",
	"termsAcknowledgement": "_2x8AR",
	"formGroup": "_2Jhpy",
	"formSection": "wLnpJ"
};