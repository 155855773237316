import React from 'react';

import ResetPasswordPage from './ResetPasswordPage';

async function action(context) {
  const { query } = context;
  const { token } = query;

  return {
    title: 'Reset Password',
    chunks: ['auth-contact-login-register'],
    component: <ResetPasswordPage token={token} />,
  };
}

export default action;
