import gql from 'graphql-tag';
import React from 'react';

import config from '../../../config/client-config';

import { isAuthenticated } from '../../../state/selectors/UserSelectors';

import RegisterPage from './RegisterPage';

const appRoot = config.app.root;

const title = 'Sign Up';

async function action(context) {
  const { graphqlClient, query, store } = context;
  const state = store.getState();

  if (isAuthenticated(state)) {
    return { redirect: `${appRoot}` };
  }
  const errs = [];

  const { refer, token } = query;

  if (token) {
    try {
      const mutateResult = await graphqlClient.mutate({
        mutation: gql`
          mutation verifyEmail($token: String!) {
            verifyEmail(token: $token) {
              status
            }
          }
        `,
        variables: { token },
      });
      const { data, errors } = mutateResult;
      if (Array.isArray(errors) && errors.length > 0) {
        errors.forEach(error => errs.push(error));
      } else {
        const { status } = data.verifyEmail;
        if (status === 'success') {
          return { redirect: `${appRoot}user/dashboard/` };
        }
        errs.push({
          message: [
            'Unable to verify email.',
            'Perhaps the link has expired.',
            'You may try registering again.',
            // 'You may resend verification email or try registering again.',
          ].join(' '),
        });
        // return {
        //   redirect: refer
        //     ? `/register?refer=${encodeURIComponent(refer)}`
        //     : '/register',
        // };
      }
    } catch (err) {
      errs.push(err);
    }
  }

  return {
    title,
    chunks: ['auth-contact-login-register'],
    component: <RegisterPage errors={errs} refer={refer} title={title} />,
  };
}

export default action;
