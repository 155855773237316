exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._16X4L h2{font-weight:100}._16X4L .FHzxP{text-align:center}._16X4L .jgJsd{margin-top:var(--block--spacing--xl);margin-top:var(--block--spacing--xl);display:-ms-flexbox;display:flex;text-align:center;-ms-flex-wrap:wrap;flex-wrap:wrap}._16X4L .jgJsd ._2qAdT{padding:20px;background:#fff;margin:10px;-webkit-box-shadow:var(--base--box-shadow);box-shadow:var(--base--box-shadow);box-shadow:var(--base--box-shadow);-ms-flex:1 1;flex:1 1;-webkit-transition:.3s ease;transition:.3s ease;border-radius:10px;min-width:250px}._16X4L .jgJsd ._2qAdT h3{margin:var(--block--spacing--sm) 0;margin:var(--block--spacing--sm) 0}._16X4L .jgJsd ._2qAdT ._1yyZ_ svg{font-size:30px}._16X4L .jgJsd ._2qAdT:hover{-webkit-box-shadow:var(--base--box-shadow--hover);box-shadow:var(--base--box-shadow--hover);box-shadow:var(--base--box-shadow--hover);-webkit-transform:translateY(-2px);-ms-transform:translateY(-2px);transform:translateY(-2px)}._16X4L .jgJsd ._2qAdT a{color:var(--primary--400);color:var(--primary--400)}._16X4L .jgJsd ._2qAdT a:hover{padding-left:10px}", ""]);

// exports
exports.locals = {
	"page": "_16X4L",
	"introText": "FHzxP",
	"options": "jgJsd",
	"option": "_2qAdT",
	"icon": "_1yyZ_"
};