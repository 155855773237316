import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import history from '../../../../client/history';

import { loadAccount } from '../../../state/actions/AccountActions';

import Link from '../../../components/Link';
import LoginForm from '../../../components/LoginForm';
import PageLayout from '../../../components/PageLayout';

import config from '../../../config/client-config';

import messages from '../../../locale/messages';

import s from './LoginPage.scss';

const defaultPageAfterAuth = `${config.app.root}dashboard/`;

class LoginPage extends React.PureComponent {
  static propTypes = {
    loadAccount: PTs.func.isRequired,
    refer: PTs.string,
    title: PTs.string.isRequired,
    warning: PTs.bool,
  };

  static defaultProps = {
    refer: defaultPageAfterAuth,
    warning: false,
  };

  handleLoginFormSubmitSuccess = async result => {
    const { refer } = this.props;
    const { status } = result;

    if (status === 'success') {
      await this.props.loadAccount();
      if (refer) {
        history.push(refer);
      }
    }
  };

  render() {
    const { refer, title, warning } = this.props;
    const initialValues = {};
    let registerURL = 'register/';
    if (refer) {
      initialValues.refer = refer;
      if (refer !== defaultPageAfterAuth) {
        registerURL = `register/?refer=${refer}`;
      }
    }

    return (
      <PageLayout className={s.page}>
        <div className={s.root}>
          <div className={s.container}>
            {warning && (
              <div>
                <FormattedMessage {...messages.loginConfirmation} />
              </div>
            )}
            <h1>{title}</h1>
            {/*
              <SocialLogin refer={refer} />
            */}
            <div className={s.loginContainer}>
              <LoginForm
                initialValues={initialValues}
                onSubmitSuccess={this.handleLoginFormSubmitSuccess}
              />
              <div className="text-center">
                <Link className={s.cantLogin} to="/login/trouble">
                  <FormattedMessage {...messages.cantLogin} />
                </Link>
              </div>
            </div>
            <div className={s.footerContent}>
              <p>Don't have an account?</p>
              <Link to={registerURL}>
                <FormattedMessage {...messages.signup} />
              </Link>
            </div>
          </div>
        </div>
      </PageLayout>
    );
  }
}

const mapStateToProps = null;

const mapDispatchToProps = {
  loadAccount,
};

export default withStyles(s)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(LoginPage),
);
