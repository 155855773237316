import React from 'react';

import config from '../../../config/client-config';

import { isAuthenticated } from '../../../state/selectors/UserSelectors';

import LoginPage from './LoginPage';

const title = 'Log In';

async function action(context) {
  const { query, store } = context;
  const state = store.getState();

  if (isAuthenticated(state)) {
    return { redirect: `${config.app.root}` };
  }

  const { refer } = query;
  const warning = 'verification' in query;

  return {
    title,
    chunks: ['auth-contact-login-register'],
    component: <LoginPage refer={refer} title={title} warning={warning} />,
  };
}

export default action;
