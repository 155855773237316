import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import ErrorBox from '../../../../components/ErrorBox';
import Link from '../../../../components/Link';
import PageLayout from '../../../../components/PageLayout';
import ResetPasswordForm from '../../../../components/ResetPasswordForm';
import ShadowerLink from '../../../../components/ShadowerLink';
import SuccessBox from '../../../../components/SuccessBox';
import WarningBox from '../../../../components/WarningBox';

import {
  Col,
  Grid,
  Row,
} from '../../../../incubation/components/ResponsiveGridLayout';

import { resetPassword } from '../../../../state/actions/AnonymousUserActions';

import s from './ResetPasswordPage.scss';

class ResetPasswordPage extends React.Component {
  static propTypes = {
    token: PTs.string,
  };

  static defaultProps = {
    token: null,
  };

  state = {
    resetPasswordResult: { newPassword: null, status: null },
    errors: null,
  };

  handleResetPasswordFormSubmitSuccess = result => {
    this.setState({
      resetPasswordResult: result,
    });
  };

  renderErrors() {
    const { errors } = this.state;

    return (
      <React.Fragment>
        {errors &&
          errors.map(error => <ErrorBox key={error.message} message={error} />)}
      </React.Fragment>
    );
  }

  /* eslint-disable-next-line class-methods-use-this */
  renderConfirmationCodeInvalidMessage() {
    return (
      <React.Fragment>
        <WarningBox>
          We were unable to reset your password using the supplied confirmation
          code. Please check to make sure the code has been entered correctly.
          If it has, then it may have expired.
        </WarningBox>
        <div className="text-center">
          <Link to="/login/trouble">Get a New Confirmation Code</Link>
        </div>
      </React.Fragment>
    );
  }

  renderPasswordResetMessage() {
    return (
      <React.Fragment>
        <SuccessBox>
          <h2 className={s.passwordResetHeading}>Password Reset</h2>
          <p>Your password was successfully reset.</p>
        </SuccessBox>
        <p>
          The new password is:{' '}
          <span className={s.newPassword}>
            {this.state.resetPasswordResult.newPassword}
          </span>
        </p>
        <div className="text-center">
          <ShadowerLink to="/login">Login</ShadowerLink>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { token } = this.props;
    const { resetPasswordResult } = this.state;
    const renderPasswordResetForm = resetPasswordResult.status === null;
    const renderPasswordResetMessage = resetPasswordResult.status === 'success';
    const renderConfirmationCodeInvalidMessage =
      resetPasswordResult.status === 'invalid';
    return (
      <PageLayout>
        <Grid>
          <Row>
            <Col
              lg={6}
              lgOffset={3}
              md={8}
              mdOffset={2}
              sm={10}
              smOffset={1}
              xs={12}
            >
              {this.renderErrors()}
              {renderConfirmationCodeInvalidMessage &&
                this.renderConfirmationCodeInvalidMessage()}
              {renderPasswordResetMessage && this.renderPasswordResetMessage()}
              {renderPasswordResetForm && (
                <React.Fragment>
                  <h2>Reset Your Password?</h2>
                  <ResetPasswordForm
                    className={s.resetPasswordForm}
                    initialValues={{ token }}
                    onSubmitSuccess={this.handleResetPasswordFormSubmitSuccess}
                  />
                  <div className="text-center">
                    <Link to="/login/trouble">
                      Don't have a confirmation code?
                    </Link>
                  </div>
                </React.Fragment>
              )}
            </Col>
          </Row>
        </Grid>
      </PageLayout>
    );
  }
}

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
  resetPassword: token => dispatch(resetPassword(token)),
});

const ReduxAwareResetPasswordPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordPage);

export default withStyles(s)(ReduxAwareResetPasswordPage);
