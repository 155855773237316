exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3brzP .page__body{background:var(--neutral--100);background:var(--neutral--100)}._2CzSN{padding-left:20px;padding-right:20px;margin:var(--block--spacing--xxl) 0;margin:var(--block--spacing--xxl) 0}._1paoO{margin:0 auto;max-width:450px;width:50vw;min-width:300px;background:#fff;-webkit-box-shadow:var(--base--box-shadow);box-shadow:var(--base--box-shadow);box-shadow:var(--base--box-shadow);border-radius:var(--base--border-radius);border-radius:var(--base--border-radius);-webkit-transition:var(--base--transition);transition:var(--base--transition);transition:var(--base--transition);overflow:hidden}._1paoO h1{padding:15px;background:var(--primary--400);background:var(--primary--400);color:#fff;margin:0;font-size:22.4px;font-size:1.4rem}._1paoO .xp5YP{padding:30px}._3U7na{background:var(--neutral--100);background:var(--neutral--100);padding:15px;text-align:center}._3U7na p{margin:0}a._2LhLT{display:block;margin:var(--block--spacing--sm) auto;margin:var(--block--spacing--sm) auto;background:rgba(0,0,0,0);border:0;color:var(--neutral--500);color:var(--neutral--500);text-align:center}a._2LhLT:hover{color:var(--link--color);color:var(--link--color)}", ""]);

// exports
exports.locals = {
	"page": "_3brzP",
	"root": "_2CzSN",
	"container": "_1paoO",
	"loginContainer": "xp5YP",
	"footerContent": "_3U7na",
	"cantLogin": "_2LhLT"
};