import PTs from 'prop-types';
import React from 'react';
import Alert from 'react-bootstrap/lib/Alert';

/**
 * Renders a localized information message.
 */
function InfoBox(props) {
  /*
  Implementation Note: We are using a `react-bootstrap` `Alert` component to
  implement this information box. But this can be changed to whatever works best.
  */
  return <Alert bsStyle="info">{props.children}</Alert>;
}

InfoBox.propTypes = {
  children: PTs.node.isRequired,
};

export default InfoBox;
