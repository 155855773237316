import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import history from '../../../../client/history';

import ErrorBox from '../../../components/ErrorBox';
import Link from '../../../components/Link';
import PageLayout from '../../../components/PageLayout';
import RegisterForm from '../../../components/RegisterForm';
import SuccessBox from '../../../components/SuccessBox';

import config from '../../../config/client-config';

import ErrorShape from '../../../lib/ErrorShape';

import messagesLut from '../../../locale/messages';

import { loadAccount } from '../../../state/actions/AccountActions';
import { resendVerifyEmail } from '../../../state/actions/AnonymousUserActions';

import s from './RegisterPage.scss';

const defaultPageAfterAuth = `${config.app.root}dashboard/`;

class RegisterPage extends React.Component {
  static propTypes = {
    errors: PTs.arrayOf(ErrorShape.isRequired),
    loadAccount: PTs.func.isRequired,
    // The client-side route/path to redirect after a successful login.
    refer: PTs.string,
    resendVerifyEmail: PTs.func.isRequired,
    title: PTs.string.isRequired,
  };

  static defaultProps = {
    errors: [],
    refer: defaultPageAfterAuth,
  };

  state = {
    flash: null,
    mode: 'new', // One of 'new', 'pending', 'success'.
    registration: null,
  };

  handleRegisterFormSubmitSuccess = async result => {
    const { refer } = this.props;
    const { registration, status } = result;
    if (status === 'success') {
      await this.props.loadAccount();
      if (refer) {
        history.push(refer);
      }
    } else if (status === 'pending') {
      const message = (
        <React.Fragment>
          <h2>Registration successful.</h2>
          <p>Email was sent to {registration.email}.</p>
          <p>To complete registration, please verify email address.</p>
        </React.Fragment>
      );
      this.setState({
        flash: message,
        mode: 'pending',
        registration,
      });
    }
  };

  handleResendVerifyEmail = async evt_unused => {
    await this.props.resendVerifyEmail();
    const { registration } = this.state;
    const message = `Email was sent to ${registration.email}.`;
    this.setState({
      flash: message,
    });
  };

  render() {
    const { errors, refer, title } = this.props;
    const { flash, mode } = this.state;
    const initialValues = {};
    let loginURL = 'login/';
    if (refer) {
      initialValues.refer = refer;
      if (refer !== defaultPageAfterAuth) {
        loginURL = `login/?refer=${refer}`;
      }
    }

    return (
      <PageLayout className={s.page}>
        <div className={s.root}>
          <div className={s.container}>
            {errors.map(error => (
              <ErrorBox key={error.message} message={error} />
            ))}
            <h1>{title}</h1>
            <div className={s.registerContainer}>
              {mode === 'new' && (
                <React.Fragment>
                  {/*
                  <SocialLogin refer={refer} />
                */}

                  <RegisterForm
                    initialValues={initialValues}
                    onSubmitSuccess={this.handleRegisterFormSubmitSuccess}
                  />
                </React.Fragment>
              )}

              {mode === 'pending' && (
                <React.Fragment>
                  {flash && <SuccessBox>{flash}</SuccessBox>}
                  <Button onClick={this.handleResendVerifyEmail} type="button">
                    Resend Verification Email
                  </Button>
                </React.Fragment>
              )}

              {mode !== 'pending' && (
                <p className={s.termsAcknowledgement}>
                  <small>
                    <FormattedMessage {...messagesLut.terms1} />
                    <span>
                      <Link to="/policies/terms-of-service">
                        <FormattedMessage {...messagesLut.termsOfService} />.
                      </Link>
                    </span>
                  </small>
                </p>
              )}
            </div>
            <div className={s.footerContent}>
              <div className={cx(s.formGroup, s.formSection)}>
                <p className={s.switchMessage}>Already have an account?</p>
                <Link to={loginURL}>
                  <FormattedMessage {...messagesLut.login} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    );
  }
}

const mapStateToProps = null;

const mapDispatchToProps = {
  loadAccount,
  resendVerifyEmail,
};

const ReduxAwareRegisterPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisterPage);

export default withStyles(s)(ReduxAwareRegisterPage);
