import React from 'react';

import InfoBox from '../../../../components/InfoBox';
import PageLayout from '../../../../components/PageLayout';
import ResetPasswordRequestForm from '../../../../components/ResetPasswordRequestForm';
import ShadowerLink from '../../../../components/ShadowerLink';
import SuccessBox from '../../../../components/SuccessBox';

import {
  Col,
  Grid,
  Row,
} from '../../../../incubation/components/ResponsiveGridLayout';

class LoginTroublePage extends React.PureComponent {
  state = {
    resetPasswordRequest: null,
    status: null,
  };

  handleResetPasswordSubmitSuccess = result => {
    const { resetPasswordRequest, status } = result;
    this.setState({ resetPasswordRequest, status });
  };

  renderRequestStatus() {
    const { resetPasswordRequest, status } = this.state;

    if (status === 'success') {
      return (
        <SuccessBox>
          An email to reset your password has been sent to{' '}
          <span>{resetPasswordRequest.email}</span>.
        </SuccessBox>
      );
    }
    if (status === 'unregistered') {
      return (
        <React.Fragment>
          <InfoBox>
            No user has completed the registration process using{' '}
            <span>{resetPasswordRequest.email}</span>. You may try registering
            with this email if you like.
          </InfoBox>
          <ShadowerLink to="/register">Register</ShadowerLink>
          <hr />
        </React.Fragment>
      );
    }
    return null;
  }

  render() {
    return (
      <PageLayout>
        <Grid>
          <Row>
            <Col
              lg={6}
              lgOffset={3}
              md={8}
              mdOffset={2}
              sm={10}
              smOffset={1}
              xs={12}
            >
              {this.renderRequestStatus()}
              <h2>Having Trouble Logging In?</h2>
              <p>
                You may submit a request to reset the password for your account.
                Enter your email address below, click Submit, and we'll send you
                a link to reset your password.
              </p>
              <ResetPasswordRequestForm
                onSubmitSuccess={this.handleResetPasswordSubmitSuccess}
              />
            </Col>
          </Row>
        </Grid>
      </PageLayout>
    );
  }
}

export default LoginTroublePage;
