exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".hbW8X{padding:4px;background:#eee;border-radius:var(--base--border-radius);border-radius:var(--base--border-radius);font-weight:700}.uzHla{margin-top:0}.crxYJ{margin-bottom:var(--block--spacing--sm);margin-bottom:var(--block--spacing--sm)}", ""]);

// exports
exports.locals = {
	"newPassword": "hbW8X",
	"passwordResetHeading": "uzHla",
	"resetPasswordForm": "crxYJ"
};