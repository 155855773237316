import React from 'react';

import ContactPage from './ContactPage';

async function action() {
  return {
    title: 'Contact',
    chunks: ['auth-contact-login-register'],
    component: <ContactPage />,
  };
}

export default action;
