import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import FormUtils from 'qc-dom_utils/lib/cjs/form';
import ReduxFormUtils from 'qc-redux-form_utils/lib/cjs';
import React from 'react';
import { propTypes, reduxForm, SubmissionError } from 'redux-form';

import I18nErrorBox from '../../components/I18nErrorBox';

import FieldGroup from '../../components/Form/FieldGroup';
import ShadowerButton from '../../components/ShadowerButton';

import { resetPassword } from '../../state/actions/AnonymousUserActions';

import s from './ResetPasswordForm.scss';

class ResetPasswordForm extends React.Component {
  static propTypes = {
    ...propTypes,
    className: PTs.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { error, form: formName, handleSubmit, submitting } = this.props;
    const { className } = this.props;

    return (
      <form className={className} name={formName} onSubmit={handleSubmit}>
        {error && <I18nErrorBox message={error} />}
        <p>
          Please enter the confirmation code sent to you in the Reset Password
          Request email.
        </p>
        <FieldGroup
          label="Confirmation Code"
          name="token"
          required
          type="text"
        />
        <ShadowerButton disabled={submitting} type="submit">
          Reset Password
        </ShadowerButton>
      </form>
    );
  }
}

const FORM_NAME = 'ResetPasswordForm';

const RF = reduxForm({
  form: FORM_NAME,
  /**
   * @returns {Object} result
   * @property {string} newPassword
   * @property {'success'} status
   *
   * @throws {SubmissionError}
   */
  onSubmit: async (values, dispatch, props_unused) => {
    const { token } = values;

    const result = await dispatch(resetPassword(token));
    const { data = {}, status } = result;
    if (status === 'failed') {
      throw new SubmissionError({ _error: 'shadower.somethingWentWrong' });
    }
    const { newPassword } = data;
    return { newPassword, status };
  },
  onSubmitFail: (errors, dispatch_unused, submitError_unused, props_unused) => {
    FormUtils.focusFirstInvalid(
      FORM_NAME,
      ReduxFormUtils.flattenErrors(errors),
    );
  },
  validate: (values_unused, props_unused) => {
    // Nothing special to validate.
  },
})(ResetPasswordForm);

export default withStyles(s)(RF);
