import React from 'react';

import config from '../../../../config/client-config';

import { isAuthenticated } from '../../../../state/selectors/UserSelectors';

import LoginTroublePage from './LoginTroublePage';

const title = 'Log In Trouble';

async function action(context) {
  const { store } = context;
  const state = store.getState();

  if (isAuthenticated(state)) {
    return { redirect: `${config.app.root}` };
  }

  return {
    title,
    chunks: ['auth-contact-login-register'],
    component: <LoginTroublePage />,
  };
}

export default action;
