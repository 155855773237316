import withStyles from 'isomorphic-style-loader/lib/withStyles';
import React from 'react';

import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faAngleRight,
  faWrench,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Col,
  Grid,
  Row,
} from '../../../incubation/components/ResponsiveGridLayout';

import PageLayout from '../../../components/PageLayout';

import s from './ContactPage.scss';

function ContactPage() {
  return (
    <PageLayout className={s.page}>
      <section>
        <Grid>
          <Row>
            <Col>
              <h2 className="globalSectionHeading text-align-center">
                Contact
              </h2>
              <p className={s.introText}>
                Questions about Shadower, shadowing or getting started as an
                expert? Get in touch with us today! We are here to help!&nbsp;:)
              </p>
              <div className={s.options}>
                <div className={s.option}>
                  <div className={s.icon}>
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </div>
                  <h3>General Questions</h3>
                  <p>Questions, comments or any other inquiries.</p>
                  <a href="mailto:contact@shadower.app">
                    contact@shadower.app &nbsp;
                    <FontAwesomeIcon icon={faAngleRight} />
                  </a>
                </div>
                <div className={s.option}>
                  <div className={s.icon}>
                    <FontAwesomeIcon icon={faDollarSign} />
                  </div>
                  <h3>Billing Questions</h3>
                  <p>Issues regarding payments or payouts.</p>
                  <a href="mailto:billing@shadower.app">
                    billing@shadower.app &nbsp;
                    <FontAwesomeIcon icon={faAngleRight} />
                  </a>
                </div>

                <div className={s.option}>
                  <div className={s.icon}>
                    <FontAwesomeIcon icon={faWrench} />
                  </div>
                  <h3>Technical Issues</h3>

                  <p>For technical issues and to report bugs.</p>
                  <a href="mailto:support@shadower.app">
                    support@shadower.app &nbsp;
                    <FontAwesomeIcon icon={faAngleRight} />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </section>
    </PageLayout>
  );
}

export default withStyles(s)(ContactPage);
